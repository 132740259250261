import React, { useState, useEffect } from "react";
import { csvVariableDownload, loadCsvFile } from "../../utils/streanCsv";
import { sliceTwitterCsv } from "../../utils/dataManipulation";
import "./css/FilterModalTwitter.css";
import TwitterFilterInputs from "../inputs/TwitterFilterInputs";

const FilterModalTwitter = ({ onToggle }) => {
  const csvFileUrl = `${process.env.PUBLIC_URL}/twitter_v1.csv`;
  // Get today's date and calculate October 7th of the current year
  const today = new Date();
  const currentYear = today.getFullYear();

  // Set the start date to October 7th of the current year
  const startDate = new Date(currentYear - 1, 9, 8).toISOString().split("T")[0]; // October is month 9 (0-based index)

  // Set the end date to today
  const endDate = today.toISOString().split("T")[0]; // Format as yyyy-mm-dd

  const [csvData, setCsvData] = useState([]);
  const [filters, setFilters] = useState({
    description: "",
    dateStart: startDate,
    dateEnd: endDate,
    user_posted: [],
    tagged_users: [],
    hashtags: [],
    repliesMin: 0,
    repliesMax: Number.MAX_SAFE_INTEGER,
    repostsMin: 0,
    repostsMax: Number.MAX_SAFE_INTEGER,
    likesMin: 0,
    likesMax: Number.MAX_SAFE_INTEGER,
    viewsMin: 0,
    viewsMax: Number.MAX_SAFE_INTEGER,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initCsvData = async () => {
      try {
        const data = await loadCsvFile(csvFileUrl); // Load CSV file asynchronously
        setCsvData(data); // Set the data without awaiting
      } catch (error) {
        console.error("Error loading CSV data:", error); // Log error if loading fails
      } finally {
        setLoading(false); // Set loading state to false after data is loaded
      }
    };

    initCsvData(); // Call the async function
  }, [csvFileUrl]); // This effect depends on the csvFileUrl
  // Function to filter based on date range
  const filterByDateRange = (data, startDate, endDate) => {
    return data.filter((row) => {
      const rowDate = new Date(row.date); // Assuming row.date is the column with the date
      if (startDate && endDate) {
        return rowDate >= new Date(startDate) && rowDate <= new Date(endDate);
      } else if (startDate) {
        return rowDate >= new Date(startDate);
      } else if (endDate) {
        return rowDate <= new Date(endDate);
      }
      return true;
    });
  };

  const onApply = () => {
    let filteredCsv = sliceTwitterCsv(csvData, filters);
    console.log(filteredCsv.length);
    // Apply date filter if both startDate and endDate are provided
    if (filters.dateStart && filters.dateEnd) {
      filteredCsv = filterByDateRange(
        filteredCsv,
        filters.dateStart,
        filters.dateEnd
      );
    }
    console.log(`Filtered Rows: ${filteredCsv.length}`);
    csvVariableDownload(filteredCsv);
    onToggle();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleAddFeatureItem = (feature, item) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [feature]: [...prevFilters[feature], item],
    }));
    console.log(filters[feature]);
  };

  const handleRemoveItem = (feature, item) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [feature]: prevFilters[feature].filter(
        (currentItem) => currentItem !== item
      ),
    }));
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Apply Filters</h2>
        <TwitterFilterInputs
          filters={filters}
          handleChange={handleChange}
          handleAddFeatureItem={handleAddFeatureItem}
          handleRemoveItem={handleRemoveItem}
        />
        <button onClick={onApply} disabled={loading}>
          {loading ? "Loading..." : "Apply Filters"}
        </button>
        <button onClick={onToggle}>Close</button>
      </div>
    </div>
  );
};

export default FilterModalTwitter;
