export const sliceTelegramCsv = (csvData, filters) => {
  // Log the CSV columns (keys of the first row)
  if (csvData.length > 0) {
    console.log("CSV Columns:", Object.keys(csvData[0]));
  }

  const filteredData = csvData.filter((row) => {
    const rowDateTime = new Date(`${row.date}T${row.time}`);
    const startDate = filters.startDate ? new Date(filters.startDate) : null;
    const endDate = filters.endDate ? new Date(filters.endDate) : null;
    const betweenDates =
      (!startDate || rowDateTime >= startDate) &&
      (!endDate || rowDateTime <= endDate);

    const channelNameKey = "channel name";
    const channelExists = channelNameKey in row;
    const channelMatches =
      !filters.channels.length ||
      (channelExists && filters.channels.includes(row[channelNameKey].trim()));

    const content = row.content;
    const belongToChannels =
      !content ||
      !filters.keywords.length ||
      filters.keywords.some((keyword) => content.includes(keyword));

    return belongToChannels && betweenDates && channelMatches;
  });

  return filteredData;
};

export const sliceTwitterCsv = (csvData, filters) => {
  return csvData.filter((row) => {
    // Convert date_posted to Date object for comparison
    const rowDate = new Date(row.date_posted);
    const startDate = filters.dateStart ? new Date(filters.dateStart) : null;
    const endDate = filters.dateEnd ? new Date(filters.dateEnd) : null;
    const betweenDates =
      (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate);

    // Filter for user_posted
    const includesUserPoster =
      !filters.user_posted.length ||
      filters.user_posted.some((user) => row.user_posted.includes(user));

    // Filter for description
    const includesDescription =
      !filters.description ||
      row.description.toLowerCase().includes(filters.description.toLowerCase());
    console.log("row.tagged_users", row.tagged_users);
    // Filter for tagged_users
    const includesTaggedUsers =
      !filters.tagged_users ||
      (Array.isArray(row.tagged_users) &&
        row.tagged_users.some((user) => filters.tagged_users.includes(user)));

    // // Filter for tagged_users
    // const includesTaggedUsers =
    //   !filters.tagged_users ||
    //   (row.tagged_users &&
    //     row.tagged_users.some((user) => filters.tagged_users.includes(user)));

    // Filter for replies (with range support for min/max)
    const isRepliesMatch =
      row.replies >= filters.repliesMin && row.replies <= filters.repliesMax;

    // Filter for reposts (with range support for min/max)
    const isRepostsMatch =
      row.reposts >= filters.repostsMin && row.reposts <= filters.repostsMax;

    // Filter for likes (with range support for min/max)
    const isLikesMatch =
      row.likes >= filters.likesMin && row.likes <= filters.likesMax;

    // Filter for views (with range support for min/max)
    const isViewsMatch =
      row.views >= filters.viewsMin && row.views <= filters.viewsMax;

    // Filter for hashtags
    const includesHashtags =
      !filters.hashtags ||
      (Array.isArray(row.hashtags) &&
        row.hashtags.some((tag) => filters.hashtags.includes(tag)));
    // // Filter for hashtags
    // const includesHashtags =
    //   !filters.hashtags ||
    //   (row.hashtags &&
    //     row.hashtags.some((tag) => filters.hashtags.includes(tag)));

    // Return the rows that match all the filters
    return (
      betweenDates &&
      includesUserPoster &&
      includesDescription &&
      includesTaggedUsers &&
      isRepliesMatch &&
      isRepostsMatch &&
      isLikesMatch &&
      isViewsMatch &&
      includesHashtags
    );
  });
};
