const twitterUniqueUsers = [
  "aawsat_eng",
  "AFP",
  "ahramonline",
  "AJEnglish",
  "AlArabiya_Eng",
  "antonioguterres",
  "ArgentinaMFA",
  "BBCBreaking",
  "BBCNews",
  "BBCWorld",
  "BelgiumMFA",
  "BelTel",
  "business",
  "CBCNews",
  "CNBC",
  "CNN",
  "cnnbrk",
  "cnni",
  "DanishMFA",
  "DavidSaranga",
  "DIRCO_ZA",
  "dwnews",
  "euronews",
  "eu_eeas",
  "FCDOGovUK",
  "ForeignOfficeKE",
  "FoxNews",
  "FRANCE24",
  "francediplo_EN",
  "FT",
  "GermanyDiplo",
  "guardian",
  "Haaretz",
  "haaretzcom",
  "IAFsite",
  "IDF",
  "IDFSpokesperson",
  "IRIMFA_EN",
  "Israel",
  "IsraelHayomHeb",
  "IsraelHebrew",
  "IsraeliPM",
  "IsraelMFA",
  "ItalyMFA",
  "Itamaraty_EN",
  "Jerusalem_Post",
  "KyivIndependent",
  "KyivPost",
  "Latvian_MFA",
  "LeMonde_EN",
  "LithuaniaMFA",
  "MEAIndia",
  "MFAestonia",
  "MFATurkiye",
  "MFA_Austria",
  "mfa_russia",
  "MFA_Ukraine",
  "MofaJapan_en",
  "MOFAkr_eng",
  "MofaQatar_EN",
  "mofauae",
  "mtlgazette",
  "nadplo",
  "NationAfrica",
  "NATO",
  "netanyahu",
  "ntvkenya",
  "nytimes",
  "ofirgendelman",
  "Palestine_UN",
  "politico",
  "POLITICOEurope",
  "POTUS",
  "Reuters",
  "RT_com",
  "RwandaMFA",
  "SecBlinken",
  "SecDef",
  "SkyNews",
  "SkyNewsBreak",
  "SpainMFA",
  "SPIEGEL_English",
  "SputnikInt",
  "StandardKenya",
  "StateDept",
  "SweMFA",
  "TechCrunch",
  "Telegraph",
  "TheEconomist",
  "TheScotsman",
  "thetimes",
  "TheTorontoSun",
  "UAEinIsrael",
  "UgandaMFA",
  "UN",
  "WhiteHouse",
  "WIRED",
  "WSJ",
  "YahooNews",
  "the_hindu",
  "ynetalerts",
  "IrishTimes",
  "nationalpost",
  "timesofindia",
  "DailyMonitor",
  "EconomicTimes",
];
export { twitterUniqueUsers };
